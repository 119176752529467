<script>
import Layout from "@/router/website-template/main";
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
/**
 * FAQ Component
 */
export default {
  page: {
    title: "FAQ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout
  },
  data() {
    return {
      title: "FAQ",
      items: [
        {
          text: "Beranda",
          href: "/",
        },
        {
          text: "FAQ",
          active: true,
        },
      ],

      id: '99cade34-ab91-4dad-8ac2-a46924362461',

      // variable Page
      nama_halaman: null,
      banner_path: null,
      konten: null,
      gambar_halaman_path: null,
      kategori: null,
      meta_content: [],
      accordion: 'accordion-'
    };
  },

  mounted() {
    if (localStorage.getItem('reloaded')) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem('reloaded');
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem('reloaded', '1');
      location.reload();
    }
    let self = this;
    // get data halaman berdasarkan id
    var config = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-website/" + self.id,
      params: {

      },
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          var data_edit = response_data_fix;
          self.nama_halaman = data_edit.nama_halaman;
          self.banner_path = data_edit.banner;
          self.banner_full = process.env.VUE_APP_BACKEND_URL + data_edit.banner;
          self.konten = data_edit.konten;
          self.gambar_halaman_path = data_edit.gambar_halaman;
          self.gambar_halaman_full = process.env.VUE_APP_BACKEND_URL + data_edit.gambar_halaman;
          self.kategori = data_edit.kategori?.nama_kategori_halaman;

          // META CONTENT
          if (data_edit.meta_content) {
            var json_response_meta_content = JSON.parse(
              data_edit.meta_content
            );
            let clear_data_json_response_meta_content = [];
            $.each(
              json_response_meta_content,
              function (indexInArray, valueOfElement) {
                clear_data_json_response_meta_content.push({
                  pertanyaan: valueOfElement.pertanyaan,
                  jawaban: valueOfElement.jawaban,
                });
              }
            );
            self.meta_content = clear_data_json_response_meta_content;
          }

          Swal.close();
        } else {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.close();
      });
  },
  methods: {

  },
};
</script>

<template>
  <Layout>
    <div class="row mt-5">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div style="margin-top:50px;">
              <h1 class="text-center mb-4">Frequently Asked Question</h1>
              <div class="row">
                <div class="col-md-6 mx-auto">
                  <div class="card">
                    <div class="card-body">
                      <div role="tablist">
                        <template v-for="(item, index) of meta_content" :key="index">
                          <b-card no-body class="mb-1 shadow-none">
                            <b-card-header class="bg-secondary mb-2 shadow rounded" header-tag="header" role="tab">
                              <h6 class="m-0">
                                <a data-bs-toggle="collapse" :data-bs-target="'#accordion-' + index" class="text-light"
                                  href="javascript: void(0);">{{ (index + 1) }} . {{ item.pertanyaan }}</a>
                              </h6>
                            </b-card-header>
                            <b-collapse :id="accordion + index" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                                <div v-html="item.jawaban"></div>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
